body {
  margin: 0;
  padding: 0;
  font-family: "Courier Prime", monospace;
  -webkit-font-smoothing: antialiased;
  color: white;
}
.App {
  background-image: linear-gradient(
    to right top,
    #f72585,
    #e0018d,
    #c40094,
    #a5009b,
    #80009f,
    #6e01a1,
    #5906a3,
    #3e0ca4,
    #410ca5,
    #430ca6,
    #460ca7,
    #480ca8
  );
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
}
.content {
  height: 90vh;
  padding: 0 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
h1 {
  padding: 0;
  margin: 0;
  font-size: 1.2rem;
  max-width: 1000px;
}
form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
input {
  height: 2.5rem;
  width: 45%;
  max-width: 500px;
  background-color: #480ca8;
  border: none;
  outline: none;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
}
button {
  width: 120px;
  background-color: #f72585;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  color: white;
  padding: 30px 50px;
  border: none;
  outline: none;
  border-radius: 3px;
  transition: all 200ms ease-in-out;
  margin-left: -10px;
}
button:hover {
  cursor: pointer;
  transform: scale(1.04);
}
button:active {
  cursor: pointer;
  transform: scale(1.04);
  background-color: #c7236d;
}
span {
  color: #f72585;
}

.timer-section {
  margin-top: 20px;
  width: 80%;
  max-width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.time-wrapper {
  background-color: #480ca8;
  border-radius: 5px;
  height: 60px;
  width: 50px;
  display: flex;
  justify-content: center;
}

.secondary-text {
  margin-top: 7px;
}
.muted {
  opacity: 0.9;
}
.alert {
  height: 80px;
  width: 70%;
  background-color: #480ca8;
  box-shadow: 2px 2px 5px rgba(53, 53, 53, 0.872);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10vh;
  left: 15%;
  text-align: center;
  max-width: 900px;
}
.loading {
  height: 100vh;
  width: 100%;
  position: fixed;
  background-image: linear-gradient(
    to right top,
    #f72585,
    #e0018d,
    #c40094,
    #a5009b,
    #80009f,
    #6e01a1,
    #5906a3,
    #3e0ca4,
    #410ca5,
    #430ca6,
    #460ca7,
    #480ca8
  );
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 1px solid #f72585;
  border-top: 1px;
  animation: spin 1s linear infinite;
}
.fail {
  background-color: #f72585;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
@media only screen and (min-width: 680px) {
  .content {
    padding: 0 15%;
    align-items: flex-start;
    text-align: left;
  }
  .secondary-text {
    margin-top: 7px;
    font-size: 1.2rem;
  }
  .form {
    justify-content: left;
  }
  h1 {
    font-size: 2rem;
  }
  .alert {
    top: 20vh;
  }
}
@media only screen and (min-width: 1600px) {
  .content {
    padding: 0 20%;
    align-items: flex-start;
    text-align: left;
  }
  .secondary-text {
    margin-top: 7px;
    font-size: 1.2rem;
  }
  .form {
    justify-content: left;
  }
  h1 {
    font-size: 2rem;
  }
  .alert {
    left: 20%;
  }
}
